<template>
  <div>
    <FilterTransferRequest
      @getRichiesteTrasferimentiList="searchTrasfRichiesteList"
      @resetFilters="resetFilters"
      :resetted="resetted"
    />
    <TableTransferRequest
      @getRichiesteTrasferimentiList="getRichiesteTrasferimentiList"
      @resetFilters="resetFilters"
      @setAvvisoObbRichiesta="setAvvisoObbRichiesta"
      :resetted="resetted"
      :avvioRicerca="avvioRicerca"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  getCurrentInstance,
  ref,
} from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { trimInput } from "@/composables/trimInput";
import FilterTransferRequest from "@/components/components-fit/tesserati/richiedi-trasferimento/FiltriRichiesteTransf.vue";
import TableTransferRequest from "@/components/components-fit/tesserati/richiedi-trasferimento/TableRichiesteTransf.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "richieste-trasferimento",
  components: {
    FilterTransferRequest,
    TableTransferRequest,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Richieste di Trasferimento", []);
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const rowsToSkip = computed(() => store.getters.rowsToSkipTrasfRichieste);
    const fetchRows = computed(() => store.getters.fetchRowsTrasfRichieste);
    const sortColumn = computed(() => store.getters.sortColumnTrasfRichieste);
    const sortOrder = computed(() => store.getters.sortOrderTrasfRichieste);

    const anno_prov = computed(() => store.getters.annoProvTrasfRichieste);
    const id_societa_prov = computed(
      () => store.getters.idSocietaProvTrasfRichieste
    );
    const cognome = computed(() => store.getters.cognomeTrasfRichieste);
    const nome = computed(() => store.getters.nomeTrasfRichieste);
    const id_categoria = computed(
      () => store.getters.id_categoriaTrasfRichieste
    );
    const id_disciplina = computed(
      () => store.getters.id_disciplinaTrasfRichieste
    );
    const cod_tessera = computed(
      () => store.getters.codiceTesseraTrasfRichieste
    );

    const avvioRicerca = ref(false);

    const getRichiesteTrasferimentiList = () => {
      avvioRicerca.value = true;
      store.dispatch("setStoreListData", {
        keys: {
          anno: anno_prov.value,
          societa: id_societa_prov.value,
          cognome: trimInput(cognome.value),
          nome: trimInput(nome.value),
          cod_tessera: trimInput(cod_tessera.value),
          id_categoria: id_categoria.value,
          id_disciplina: id_disciplina.value,
          rowstoskip: rowsToSkip.value,
          fetchrows: fetchRows.value,
          sortcolumn: sortColumn.value,
          sortorder: sortOrder.value,
        },
        apiLink: globalApi.TRASFERIMENTI_RICHIESTE_LIST,
        itemName: "trasferimenti_richieste_list",
      });
    };
    const resetted = ref(false);

    const resetFilters = () => {
      avvioRicerca.value = false;
      store.commit("resetFiltersTrasfRichieste");
      store.commit("spliceArrayFromName", "tesseramento_tipologie");
      resetted.value = true;
      setTimeout(() => {
        resetted.value = false;
      }, 1000);
      //getRichiesteTrasferimentiList();
    };
    const loaded = computed(() =>
      store.getters.getStateFromName("loadedtrasferimenti_richieste_list")
    );
    const searchTrasfRichiesteList = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipTrasfRichieste");
      getRichiesteTrasferimentiList();
    };

    const setAvvisoObbRichiesta = () => {
      Swal.fire({
        icon: "error",
        title: "Compila tutti i campi obbligatori",
        html: "Per inviare la richiesta è necessario indicare:<ul class=text-start><li>Società di provenienza</li><li>Società di destinazione</li><li>Stagione provenienza</li><li>Stagione trasferimento</li><li>Selezionare i tesserati dai risultati della ricerca</li></ul>",
      });
    };

    return {
      getRichiesteTrasferimentiList,
      resetFilters,
      resetted,
      setAvvisoObbRichiesta,
      avvioRicerca,
      searchTrasfRichiesteList,
    };
  },
});
</script>
