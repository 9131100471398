<template>
  <div>
    <div class="mb-10 bg-secondary p-8 rounded">
      <div class="blueFit fs-6">
        Scegliere parametri obbligatori ed eventuli parametri avanzati -
        cliccare su "Cerca" - quindi procedere con i trasferimenti
      </div>
    </div>
    <div class="card shadow">
      <form autocomplete="new-off">
        <div
          class="pt-4 pb-4 pe-4 ps-4"
          @keyup.enter="getRichiesteTrasferimentiList()"
        >
          <div class="row">
            <div class="col-sm-3 filter">
              <div class="dp__input_wrap text-gray-600">
                <input
                  placeholder="Società di provenienza"
                  name="nomeSocietaProvTrasfRichieste"
                  type="text"
                  class="form-control"
                  @input="
                    uncheckAll();
                    inputFocus = 'societa_provenienza';
                    getSocietaProv($event.target.value);
                  "
                  :value="nomeSocietaProvTrasfRichieste"
                  autocomplete="new-off"
                />
                <svg
                  @click="
                    uncheckAll();
                    setIdSocietaProvTrasfRichieste({ name: null, value: null });
                  "
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  class="dp__icon dp__clear_icon dp__input_icons"
                >
                  <path
                    d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                  ></path>
                  <path
                    d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                  ></path>
                </svg>
              </div>
              <ListSocieta
                :focusOnInput="inputFocus === 'societa_provenienza'"
                :list="societa"
                @hideList="
                  inputFocus = null;
                  resetSocieta;
                "
                @selectedSocieta="
                  resetSocietaProv();
                  setIdSocietaProvTrasfRichieste($event);
                  inputFocus = null;
                "
              ></ListSocieta>
              <p class="help-message" style="color: red" v-if="obbSocProv">
                Obbligatorio
              </p>
            </div>
            <div class="col-sm-3 filter">
              <div class="dp__input_wrap text-gray-600">
                <input
                  placeholder="Società di destinazione"
                  name="nomeSocietaDestTrasfRichieste"
                  type="text"
                  class="form-control"
                  @input="
                    uncheckAll();
                    inputFocus = 'societa_destinazione';
                    getSocieta($event.target.value);
                  "
                  :value="nomeSocietaDestTrasfRichieste"
                  autocomplete="new-off"
                />
                <svg
                  @click="
                    uncheckAll();
                    setIdSocietaDestTrasfRichieste({ name: null, value: null });
                  "
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  class="dp__icon dp__clear_icon dp__input_icons"
                >
                  <path
                    d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                  ></path>
                  <path
                    d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                  ></path>
                </svg>
              </div>
              <ListSocieta
                :list="societa"
                :focusOnInput="inputFocus === 'societa_destinazione'"
                @hideList="
                  inputFocus = null;
                  resetSocieta;
                "
                @selectedSocieta="
                  resetSocieta();
                  setIdSocietaDestTrasfRichieste($event);
                  inputFocus = null;
                "
              ></ListSocieta>
            </div>
            <div class="col-sm-3 filter">
              <SelectInput
                :options="stagioni"
                name="annoProvTrasfRichieste"
                placeholder="Stagione"
                :value="annoProvTrasfRichieste"
                @changeSelect="
                  setAnnoProvTrasfRichieste($event);
                  uncheckAll();
                "
              />
              <p class="help-message" style="color: red" v-if="obbAnnoProv">
                Obbligatorio
              </p>
            </div>
            <div class="col-sm-3">
              <SelectInput
                :options="years"
                name="annoDestTrasfRichieste"
                placeholder="Stagione trasferimento"
                :value="annoDestTrasfRichieste"
                @changeSelect="
                  setAnnoDestTrasfRichieste($event);
                  uncheckAll();
                "
              />
            </div>
          </div>

          <div id="collapseFilterSociety">
            <div class="bg-light rounded-3">
              <div class="row mt-4 pt-4 ps-2 pe-2">
                <div class="col-sm-4 filter">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Cognome"
                    aria-label=""
                    :value="cognomeTrasfRichieste"
                    @input="setCognomeTrasfRichieste"
                  />
                </div>
                <div class="col-sm-4 filter">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Nome"
                    aria-label=""
                    :value="nomeTrasfRichieste"
                    @input="setNomeTrasfRichieste"
                  />
                </div>

                <div class="col-sm-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Codice Tessera"
                    aria-label=""
                    :value="codiceTesseraTrasfRichieste"
                    @input="setCodiceTesseraTrasfRichieste"
                  />
                </div>
              </div>
              <div class="row pb-4 ps-2 pe-2">
                <div class="col-sm-4">
                  <div class="pt-4">
                    <SelectInput
                      :options="tesseramento_tipologie"
                      name="tesseramento_tipologie"
                      placeholder="Categoria"
                      :value="id_categoriaTrasfRichieste"
                      @changeSelect="setCategoriaTrasfRichieste($event)"
                    />
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="pt-4">
                    <SelectInput
                      :options="discipline"
                      name="discipline"
                      placeholder="Settore"
                      :value="id_disciplinaTrasfRichieste"
                      @changeSelect="setDisciplinaTrasfRichieste($event)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center mt-4">
              <button
                type="button"
                data-bs-toggle=""
                data-bs-target=""
                aria-expanded="false"
                aria-controls=""
                class="badge rounded-pill bg-light text-gray-600 ms-4"
                @click="getRichiesteTrasferimentiList()"
                :disabled="!loaded"
              >
                Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
              </button>
              <span
                type="button"
                data-bs-toggle=""
                data-bs-target=""
                aria-expanded="false"
                aria-controls=""
                class="badge rounded-pill bg-light text-gray-600 ms-4"
                @click="$emit('resetFilters')"
              >
                <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
              ></span>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, watch, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import ListSocieta from "../../utility/ListSocieta.vue";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";

export default defineComponent({
  name: "filtriRichiesteTrasferimenti",
  emits: ["resetFilters", "getRichiesteTrasferimentiList"],
  props: {
    campiLocked: {
      type: String,
      required: true,
    },
    resetted: {
      type: Boolean,
    },
  },
  components: {
    ListSocieta,
    SelectInput,
  },
  setup(props, { emit }) {
    const isActive = ref(false);
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const discipline = computed(() =>
      store.getters.getStateFromName("discipline")
    );
    const tesseramento_tipologie = computed(() =>
      store.getters.getStateFromName("tesseramento_tipologie")
    );

    const keys = ref("d|ttt");
    if (!discipline.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const nomeSocietaProvTrasfRichieste = computed(
      () => store.getters.nomeSocietaProvTrasfRichieste
    );
    const idSocietaProvTrasfRichieste = computed(
      () => store.getters.idSocietaProvTrasfRichieste
    );
    const setIdSocietaProvTrasfRichieste = ({ value, name }) => {
      obbSocProv.value = false;
      store.commit("setIdSocietaProvTrasfRichieste", value);
      store.commit("setNomeSocietaProvTrasfRichieste", name);
    };

    const nomeSocietaDestTrasfRichieste = computed(
      () => store.getters.nomeSocietaDestTrasfRichieste
    );
    const idSocietaDestTrasfRichieste = computed(
      () => store.getters.idSocietaDestTrasfRichieste
    );
    const setIdSocietaDestTrasfRichieste = ({ value, name }) => {
      store.commit("setIdSocietaDestTrasfRichieste", value);
      store.commit("setNomeSocietaDestTrasfRichieste", name);
    };

    const annoProvTrasfRichieste = computed(
      () => store.getters.annoProvTrasfRichieste
    );
    const setAnnoProvTrasfRichieste = (event) => {
      obbAnnoProv.value = false;
      store.commit("setAnnoProvTrasfRichieste", event);
    };

    const annoDestTrasfRichieste = computed(
      () => store.getters.annoDestTrasfRichieste
    );
    const setAnnoDestTrasfRichieste = (event) => {
      store.commit("setAnnoDestTrasfRichieste", event);
    };

    const cognomeTrasfRichieste = computed(
      () => store.getters.cognomeTrasfRichieste
    );
    const setCognomeTrasfRichieste = (e) => {
      const string = e.target.value;
      store.commit("setCognomeTrasfRichieste", string);
    };

    const nomeTrasfRichieste = computed(() => store.getters.nomeTrasfRichieste);
    const setNomeTrasfRichieste = (e) => {
      const string = e.target.value;
      store.commit("setNomeTrasfRichieste", string);
    };

    const codiceTesseraTrasfRichieste = computed(
      () => store.getters.codiceTesseraTrasfRichieste
    );
    const setCodiceTesseraTrasfRichieste = (e) => {
      const string = e.target.value;
      store.commit("setCodiceTesseraTrasfRichieste", string);
    };

    const getCategoriaTrasfRichieste = (id) => {
      store.dispatch("setStoreData", {
        keys: { id_tipo_tessera: id },
        apiLink: globalApi.TESSERAMENTO_TIPOLOGIE,
      });
    };

    const id_categoriaTrasfRichieste = computed(
      () => store.getters.id_categoriaTrasfRichieste
    );
    const setCategoriaTrasfRichieste = (event) => {
      store.commit("setCategoriaTrasfRichieste", event);
    };

    const id_disciplinaTrasfRichieste = computed(
      () => store.getters.id_disciplinaTrasfRichieste
    );
    const setDisciplinaTrasfRichieste = (event) => {
      store.commit("setDisciplinaTrasfRichieste", event);
    };

    const getSocieta = (string) => {
      store.commit("setNomeSocietaDest", string);
      if (string === "") {
        store.commit("emptySocietaNomeCodAff");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getSocietaProv", string);
      //store.dispatch("getSocietaNomeCodAff", string);
    };
    const resetSocieta = () => {
      store.commit("emptySocietaNomeCodAff");
    };

    const getSocietaProv = (string) => {
      store.commit("setNomeSocietaProv", string);
      if (string === "") {
        store.commit("emptySocietaNomeCodAff");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getSocietaProv", string);
    };
    const resetSocietaProv = () => {
      store.commit("emptySocietaNomeCodAff");
    };

    const inputFocus = ref(null);

    getCategoriaTrasfRichieste(1);

    const obbSocProv = ref(false);
    const obbAnnoProv = ref(false);

    const getRichiesteTrasferimentiList = () => {
      obbSocProv.value = false;
      obbAnnoProv.value = false;
      if (idSocietaProvTrasfRichieste.value == null) {
        obbSocProv.value = true;
      }
      if (annoProvTrasfRichieste.value == null) {
        obbAnnoProv.value = true;
      }
      if (
        idSocietaProvTrasfRichieste.value != null &&
        annoProvTrasfRichieste.value != null
      ) {
        emit("getRichiesteTrasferimentiList");
      }
    };

    watch(
      () => props.resetted,
      (value) => {
        if (value) {
          /*resetProvince();
          comuneString.value = "";*/
        }
      }
    );

    const trasferimenti_richieste_list = computed(() =>
      store.getters.getStateFromName("resultstrasferimenti_richieste_list")
    );

    const uncheckAll = () => {
      if (!trasferimenti_richieste_list.value) return;
      store.commit("spliceArrayFromName", "trasferimenti_richieste_list");
      trasferimenti_richieste_list.value.splice(
        0,
        trasferimenti_richieste_list.value.length
      );
    };

    const years = computed(() =>
      store.getters.getStateFromName("affiliazioni_stagioni_aperte")
    );
    const afp = ref("afp");
    {
      store.dispatch("setStoreData", {
        keys: { keys: afp.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    return {
      stagioni: computed(() => store.getters.stagioni),
      isActive,
      nomeTrasfRichieste,
      setNomeTrasfRichieste,
      cognomeTrasfRichieste,
      setCognomeTrasfRichieste,
      codiceTesseraTrasfRichieste,
      setCodiceTesseraTrasfRichieste,
      societa: computed(() => store.getters.societaNomeCodAff),
      id_categoriaTrasfRichieste,
      setCategoriaTrasfRichieste,
      id_disciplinaTrasfRichieste,
      setDisciplinaTrasfRichieste,
      stagione: computed(() => store.getters.stagioneSelected),
      discipline,
      tesseramento_tipologie,
      getCategoriaTrasfRichieste,
      annoProvTrasfRichieste,
      annoDestTrasfRichieste,
      setAnnoProvTrasfRichieste,
      setAnnoDestTrasfRichieste,
      nomeSocietaProvTrasfRichieste,
      idSocietaProvTrasfRichieste,
      setIdSocietaProvTrasfRichieste,
      getSocieta,
      resetSocieta,
      getSocietaProv,
      resetSocietaProv,
      nomeSocietaDestTrasfRichieste,
      idSocietaDestTrasfRichieste,
      setIdSocietaDestTrasfRichieste,
      inputFocus,
      obbSocProv,
      obbAnnoProv,
      getRichiesteTrasferimentiList,
      loaded: computed(() =>
        store.getters.getStateFromName("loadedtrasferimenti_richieste_list")
      ),
      trasferimenti_richieste_list,
      uncheckAll,
      years,
    };
  },
});
</script>

<style scoped>
.dp__input_wrap {
  position: relative;
  width: 100%;
  box-sizing: unset;
}
.dp__icon {
  stroke: currentcolor;
  fill: currentcolor;
}
.dp__clear_icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
  color: var(--dp-icon-color);
}
.dp__input_icons {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  stroke-width: 0;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 6px 12px;
  color: var(--dp-icon-color);
  box-sizing: content-box;
}
</style>
